:root {
  --black: #000000;
  --white: #ffffff;
  --black-overlay: rgba(0, 0, 0, 0.5);
  --white-overlay: rgba(255, 255, 255, 0.8);
  --grey: #848484;
  --light-grey: #f5f5f5;
  --dark-grey: #737373;

  /* Fonts */
  --font-main: "acumin-pro-wide", sans-serif;
  --font-second: "stolzl", sans-serif;

  /* Swiper */
  --swiper-navigation-color: var(--black);
  --swiper-pagination-color: var(--black);
  --swiper-pagination-bullet-inactive-color: #c4c4c4;

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 1024;
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
@custom-media --mobile-menu-start-point (max-width: 1024px);

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
@custom-media --mobile-menu-end-point (min-width: 1025px);
